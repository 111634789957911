import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';

import ServiceCustomer from '../../services/serviceCustomer';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

export default function PanelCustomerAdd(props) {

    const [token] = useRecoilState(tokenAtom);

    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const customerService = new ServiceCustomer();

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        email: Yup.string().required('This information is required').email('Please enter valid email address'),
        phone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }).required('This information is required'),
        addr: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            addr: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "name": values.name,
                "email": values.email,
                "phone": values.phone,
                "addr": values.addr,
                "status": "active"
            }
            customerService.setCustomer(body, token).then((res) => {
                setSubmit(false);
                if (res.status) {
                    toast.success("New customer added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    goBack();
                } else {
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        },
    })

    const goBack = () => {
        props.onToggle();
        navigate(`/home/customer`);
    }

    useEffect(() => {
        props.onClose();
    }, [])

    return (
        <div className="w-full h-full p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all customer</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">Add Customer</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to customer.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Customer Information</h3>
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Customer name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="name" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Full name" value={values.name} />
                                                {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Email address<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="email" onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Email address" value={values.email} />
                                                {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Phone Number<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="phone" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Phone number" value={values.phone} />
                                                {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                            </div>

                                            <div className="col-span-6">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Physical address 1<sup className="text-red-600">*</sup></label>
                                                <textarea id="addr" value={values.addr} onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Street address"></textarea>
                                                {(errors.addr && touched.addr) && <p className='text-xs text-red-400 mt-1'>{errors.addr}</p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "ADD CUSTOMER"}
                        </button>
                    </div>
                </form>
            </div >
        </div>
    )
}
