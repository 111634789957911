import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, prdAtom } from '../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Scrollbars from 'react-custom-scrollbars';

import ServiceProduct from '../../services/serviceProduct';
import ServiceCategory from '../../services/serviceCategory';
import ServiceMedia from '../../services/serviceMedia';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

export default function PanelEditProduct(props) {

    const [token] = useRecoilState(tokenAtom);
    const [pid, setPID] = useRecoilState(prdAtom);

    const [category, setCategories] = useState([]);
    const [media, setMedia] = useState([]);
    const [prodMedia, setProdMedia] = useState([]);
    const [rates, setRates] = useState([{ 'qty': 0, 'rate': 0 }]);

    const [initCat, setInitCat] = useState({});

    const [submit, setSubmit] = useState(false);
    const [isView, setIsView] = useState(false);

    const navigate = useNavigate();
    const productService = new ServiceProduct();
    const categoryService = new ServiceCategory();
    const mediaService = new ServiceMedia();

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        Description: Yup.string().required('This information is required')
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            catid: '',
            name: '',
            ChemicalClass: '',
            Dose: '',
            ShelfLife: '',
            Storage: '',
            Description: '',
            ManBy: '',
            Treatment: '',
            CoO: '',
            Composition: '',
            media: [],
            status: 'active',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            if (Object.keys(initCat).length === 0) {
                toast.error('Please select product category.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                if (prodMedia.length === 0) {
                    toast.error('Please select atleast one image for product.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                } else {
                    setSubmit(true);
                    let data = { ...values };
                    data['catid'] = initCat;
                    data['media'] = prodMedia;
                    data['rates'] = rates;

                    let body = {
                        'pid': pid,
                        'data': data
                    };

                    productService.updateProduct(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            toast.success("Product updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            goBack();
                        } else {
                            toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    });
                }
            }
        },
    });

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'cat':
                setInitCat(value);
                break;
        }
    }

    const handelQtss = (qty, index) => {
        let tempRate = [...rates];
        tempRate[index].qty = qty;
        setRates(tempRate);
    }

    const handelRates = (rate, index) => {
        let tempRate = [...rates];
        tempRate[index].rate = rate;
        setRates(tempRate);
    }

    const addRow = () => {
        let tempRate = [...rates];
        tempRate.push({ 'qty': 0, 'rate': 0 });
        setRates(tempRate);
    }

    const removeRow = (index) => {
        let tempRate = [...rates];
        tempRate.splice(index, 1);
        setRates(tempRate);
    }

    const toggleMItem = (file) => {
        let files = [...prodMedia];
        const index = files.findIndex((item) => item.filename === file.filename);
        if (index !== -1) {
            files.splice(index, 1);
        } else {
            files.push(file);
        }
        setProdMedia(files);
    }

    const checkMItem = (name) => {
        let files = [...prodMedia];
        const index = files.findIndex((item) => item.filename === name);
        return index !== -1;
    }

    const loadData = () => {
        (async function () {
            var docM = await mediaService.getAllMedia({}, token);
            setMedia(docM.data);
            var doc = await categoryService.getAllCategories({}, token);
            var cats = await doc.data.map((d) => {
                return { value: d._id, label: d.title }
            });
            setCategories(cats);

            var prod = await productService.getProductById({ 'pid': pid }, token);
            let prdVal = {
                catid: prod.data.catid,
                name: prod.data.name,
                ChemicalClass: prod.data.ChemicalClass,
                Dose: prod.data.Dose,
                ShelfLife: prod.data.ShelfLife,
                Storage: prod.data.Storage,
                Description: prod.data.Description,
                ManBy: prod.data.ManBy,
                Treatment: prod.data.Treatment,
                CoO: prod.data.CoO,
                Composition: prod.data.Composition,
            };
            setValues(prdVal);
            setInitCat(prod.data.catid);
            setRates(prod.data.rates);
            setProdMedia(prod.data.media);
        })();
    }

    const goBack = () => {
        setPID('');
        props.onToggle();
        navigate(`/home/product`);
    }

    useEffect(() => {
        props.onClose();
        loadData();
    }, []);

    return (
        <div className="w-full h-full p-2 flex flex-col overflow-auto">
            {
                isView && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] absolute right-0 h-screen p-[20px]'>
                        <Scrollbars>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900'>Folders</h1>
                                    <div className='flex items-center'>
                                        <span onClick={() => {
                                            setIsView(false);
                                        }} className='ml-3'><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className="mb-4">
                                    {
                                        media.map((med) => {
                                            return <div>
                                                <h2 className='font-sans font-medium text-gray-900 bg-gray-100 p-1'>{med.title}</h2>
                                                {
                                                    med.files.map((file, index) => {
                                                        return <div className="pl-3 pr-4 py-3 text-sm bg-gray-100 rounded border mt-2">
                                                            <div className='flex items-center justify-between'>
                                                                <div className="w-0 flex-1 flex items-center">
                                                                    {file?.ext !== "mp4" && <img src={file.fileurl} alt="Icon Template" className='w-10 h-10' />}
                                                                    <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                                                        {file.name}&nbsp;
                                                                        <i className="las la-link text-gray-400 text-lg"></i>
                                                                    </span>
                                                                </div>
                                                                <div className="ml-4 flex-shrink-0">
                                                                    <label className={`relative cursor-pointer rounded-md font-medium ${checkMItem(file.filename) ? "text-red-600" : "text-blue-600"} font-mono`} onClick={() => {
                                                                        toggleMItem(file);
                                                                    }}>
                                                                        <span>{checkMItem(file.filename) ? "Remove" : "Add"}</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            }
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all products</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">Add Product</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to product.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Product Information</h3>
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Product Category<sup className="text-red-600">*</sup></label>
                                                <Select options={category} name="cat" onChange={(e) => {
                                                    handleOnChange({ target: { name: 'cat', value: e.value } });
                                                }} value={category.filter(function (option) {
                                                    return option.value === initCat;
                                                })} className="mt-1 col-span-6 shadow-inner" />
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Product Title<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="name" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Title" value={values.name} />
                                                {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Product Chemical Class <span className="text-gray-400">(Optional)</span></label>
                                                <input type="text" id="ChemicalClass" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Chemical Class" value={values.ChemicalClass} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Product Dose <span className="text-gray-400">(Optional)</span></label>
                                                <input type="text" id="Dose" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Dose" value={values.Dose} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Product Shelf Life <span className="text-gray-400">(Optional)</span></label>
                                                <input type="text" id="ShelfLife" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Shelf Life" value={values.ShelfLife} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Product Storage <span className="text-gray-400">(Optional)</span></label>
                                                <input type="text" id="Storage" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Storage" value={values.Storage} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Product Manufactured by <span className="text-gray-400">(Optional)</span></label>
                                                <input type="text" id="ManBy" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Manufactured by" value={values.ManBy} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Product Treatment <span className="text-gray-400">(Optional)</span></label>
                                                <input type="text" id="Treatment" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Treatment" value={values.Treatment} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Product Country of origin <span className="text-gray-400">(Optional)</span></label>
                                                <input type="text" id="CoO" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Country of origin" value={values.CoO} />
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Product Composition <span className="text-gray-400">(Optional)</span></label>
                                                <input type="text" id="Composition" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Composition" value={values.Composition} />
                                            </div>

                                            <div className="col-span-6">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Product Description<sup className="text-red-600">*</sup></label>
                                                <textarea id="Description" value={values.Description} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-20 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Description"></textarea>
                                                {(errors.Description && touched.Description) && <p className='text-xs text-red-400 mt-1'>{errors.Description}</p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 flex items-center justify-between">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Product Prices</h3>
                                            </div>
                                            {
                                                rates.map((rat, index) => {
                                                    return <div className='col-span-6 grid grid-cols-8 gap-4'>
                                                        <div className="col-span-6 sm:col-span-2">
                                                            <label className="block text-xs font-medium text-gray-700 font-mono">Product Quantity<sup className="text-red-600">*</sup></label>
                                                            <input type="number" id={`qty${index}`} onChange={(e) => {
                                                                handelQtss(e.target.value, index);
                                                            }} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Quantity" value={rat.qty} />
                                                        </div>
                                                        <div className="col-span-6 sm:col-span-2">
                                                            <label className="block text-xs font-medium text-gray-700 font-mono">Product Price<sup className="text-red-600">*</sup></label>
                                                            <input type="number" id={`rate${index}`} onChange={(e) => {
                                                                handelRates(e.target.value, index);
                                                            }} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Product Price" value={rat.rate} />
                                                        </div>
                                                        <div>
                                                            {
                                                                index > 0 && <button type="button" className="mt-4 h-10 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-800" onClick={() => { removeRow(index); }}>
                                                                    REMOVE RATES
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                })
                                            }
                                            <button type="button" className="mt-4 h-10 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800" onClick={() => { addRow(); }}>
                                                ADD RATES
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 flex items-center justify-between">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Product Media</h3>
                                                <div className='text-blue-600 mr-2 cursor-pointer' onClick={() => {
                                                    setIsView(true);
                                                }}><i className="las la-pen"></i>Add</div>
                                            </div>
                                            <div className='col-span-6 my-2 grid grid-cols-8 gap-4'>
                                                {
                                                    prodMedia.length !== 0 && prodMedia.map((med) => {
                                                        return <div className='border col-span-1 rounded-md overflow-hidden'>
                                                            {med?.ext !== "mp4" && <img src={med.fileurl} alt="Icon Template" className='w-full h-full object-cover' />}
                                                        </div>
                                                    })
                                                }
                                                {
                                                    prodMedia.length === 0 && <div>
                                                        <span>No media found.</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "UPDATE PRODUCT"}
                        </button>
                    </div>
                </form>
            </div >
        </div>
    )
}
