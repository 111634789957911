import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceProduct {
    lookupService = new APIServiceLookUp();

    async getAllProducts(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}product/getAllProducts`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setProduct(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}product/setProduct`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getProductById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}product/getProductById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateProduct(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}product/updateProduct`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}