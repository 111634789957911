import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceLead {
    lookupService = new APIServiceLookUp();

    async getAllLeads(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}lead/getAllLeads`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateLeadById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}lead/updateLead`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteLeadById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}lead/deleteLeadById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}