import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, ordAtom } from '../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import Select from 'react-select';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceProduct from '../../services/serviceProduct';
import ServiceOrder from '../../services/serviceOrder';
import ServiceEmail from '../../services/serviceEmail';

export default function PanelOrderEdit() {

    const [token] = useRecoilState(tokenAtom);
    const [oid, setOID] = useRecoilState(ordAtom);

    const [products, setProducts] = useState([]);
    const [items, setItems] = useState([]);

    const [submit, setSubmit] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const navigate = useNavigate();
    const productService = new ServiceProduct();
    const orderService = new ServiceOrder();
    const emailService = new ServiceEmail();

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        email: Yup.string().required('This information is required').email('Please enter valid email address'),
        phone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }).required('This information is required'),
        addr: Yup.string().required('This information is required'),
    });

    const formVSchemaA = Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('This information is required'),
            qty: Yup.string().required('This information is required'),
            rate: Yup.string().required('This information is required'),
        })
    );

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            addr: '',
            items: [],
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            formVSchemaA.isValid(items).then(function (valid) {
                setIsValid(!valid);
                if (valid) {
                    setSubmit(true);
                    let body = {
                        "name": values.name,
                        "email": values.email,
                        "phone": values.phone,
                        "addr": values.addr,
                        "items": getItems(),
                        "status": "active"
                    }
                    orderService.addOrder(body, token).then((res) => {
                        setSubmit(false);
                        if (res.status) {
                            let bodyEmail = {
                                name: values.name,
                                email: values.email,
                                oid: res.data.OID
                            };
                            emailService.sendEmailA(bodyEmail, token).then((res) => {
                                toast.success("New order added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                                goBack();
                            });
                        } else {
                            toast.error('API not pentrated please wait..!!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                    });
                }
            });
        },
    });

    const getItems = () => {
        let tempItems = [...items];
        let mainItems = [];
        tempItems.forEach((selItem) => {
            mainItems.push({
                "pid": selItem.name,
                "pname": products.filter((prd) => prd._id === selItem.name)[0].name,
                "purl": products.filter((prd) => prd._id === selItem.name)[0].media.length !== 0 ? products.filter((prd) => prd._id === selItem.name)[0].media[0].fileurl : '',
                "rate": selItem.rate.toString(),
                "qty": selItem.qty.toString()
            });
        });
        return mainItems;
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": {},
                "page": 1,
                "size": 100,
                "searchQuery": "",
                "startDate": "",
                "endDate": ""
            };
            var res = await productService.getAllProducts(body, token);
            if (res['status']) {
                setProducts(res.data.data);
                let val = [...items];
                val = [];
                val.push({ name: '', rate: 0, qty: 0 });
                setItems(val);

                let bodyOrd = { 'oid': oid };
                var resOrd = await orderService.getOrderById(bodyOrd, token);

                let ordVal = {
                    name: resOrd.data.customer.name,
                    email: resOrd.data.customer.email,
                    phone: resOrd.data.customer.phone,
                    addr: resOrd.data.customer.addr
                }
                setValues(ordVal);
                let revItem = [];

                resOrd.data.items.map((item) => {
                    revItem.push({ name: item.pid, rate: item.rate, qty: item.qty });
                });
                setItems(revItem);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const getProductsOption = () => {
        var proFiles = products.map((d) => {
            return { value: d._id, label: d.name }
        });
        return proFiles;
    }

    const handleOnChange = (e, index) => {
        var { value, name } = e.target;
        let tempVal = [...items];
        switch (name) {
            case 'pname':
                tempVal[index].name = value;
                break;
            case 'pqty':
                tempVal[index].qty = value;
                break;
            case 'prate':
                tempVal[index].rate = value;
                break;
            default:
                break;
        }
        setItems(tempVal);
    }

    const addRow = () => {
        let val = [...items];
        val.push({ name: '', rate: 0, qty: 0 });
        setItems(val);
    }

    const delRow = (index) => {
        let val = [...items];
        val.splice(index, 1)
        setItems(val);
    }

    useEffect(() => {
        loadData();
    }, []);

    const goBack = () => {
        setOID('');
        navigate(`/home/order`);
    }

    return (
        <div className="w-full h-full p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all orders</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">Add Order</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to order.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Customer Information</h3>
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Email address<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="email" onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Email address" value={values.email} />
                                                {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Customer name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="name" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Full name" value={values.name} />
                                                {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Phone Number<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="phone" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Phone number" value={values.phone} />
                                                {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                            </div>

                                            <div className="col-span-6">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Physical address 1<sup className="text-red-600">*</sup></label>
                                                <textarea id="addr" value={values.addr} onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Street address"></textarea>
                                                {(errors.addr && touched.addr) && <p className='text-xs text-red-400 mt-1'>{errors.addr}</p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-span-12">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Order Information</h3>
                                            </div>

                                            {
                                                items.map((item, index) => {
                                                    return <div className='col-span-12 grid grid-cols-12 gap-6'>
                                                        <div className="col-span-6">
                                                            <label className="block text-xs font-medium text-gray-700 font-mono">Product <sup className="text-red-600">*</sup></label>
                                                            <Select options={getProductsOption()} name="pname" onChange={(e) => {
                                                                handleOnChange({ target: { name: 'pname', value: e.value } }, index);
                                                            }} value={getProductsOption().filter(function (option) {
                                                                return option.value === items[index].name;
                                                            })} className="mt-1 basic-single col-span-6 shadow-inner" classNamePrefix="select" />
                                                            {(isValid && items[index].name === "") && <span className='text-xs text-red-400 mt-1'>This information is required</span>}
                                                        </div>

                                                        <div className="col-span-2">
                                                            <label className="block text-xs font-medium text-gray-700 font-mono">Unit Quantity<sup className="text-red-600">*</sup></label>
                                                            <input type="number" id="pqty" onChange={(e) => {
                                                                handleOnChange({ target: { name: 'pqty', value: e.target.value } }, index);
                                                            }} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Full name" value={items[index].qty} />
                                                        </div>

                                                        <div className="col-span-2">
                                                            <label className="block text-xs font-medium text-gray-700 font-mono">Unit Rate<sup className="text-red-600">*</sup></label>
                                                            <input type="number" id="prate" onChange={(e) => {
                                                                handleOnChange({ target: { name: 'prate', value: e.target.value } }, index);
                                                            }} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Phone number" value={items[index].rate} />
                                                        </div>

                                                        <div className="col-span-2">
                                                            <div className='flex mt-4'>
                                                                <button type="button" className="bg-blue-600 text-white w-[36px] h-[36px] rounded-md" title="Delete" onClick={() => { addRow() }}>
                                                                    <i className="las la-plus-square"></i>
                                                                </button>
                                                                {
                                                                    index !== 0 && <button type="button" className="bg-red-600 text-white w-[36px] h-[36px] rounded-md ml-2" title="Delete" onClick={() => { delRow(index) }}>
                                                                        <i className="las la-trash text-xl"></i>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "ADD ORDER"}
                        </button>
                    </div>
                </form >
            </div >
        </div >
    )
}
