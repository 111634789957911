import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import PanelCustomerList from '../panels/customer/PanelCustomerList';
import PanelCustomerAdd from '../panels/customer/PanelCustomerAdd';
import PanelLeads from '../panels/customer/PanelLeads';

import PanelDev from '../panels/dev/PanelDev';

export default function LayoutCustomer() {
    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-xl lg:text-2xl font-medium pt-4 ml-6">Profile Menu</motion.h1>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start mt-2 lg:mt-4">
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'customer' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('customer') }}>Customers</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'lead' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('lead') }}>Leads</li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'customer':
                                return (<PanelCustomerList onToggle={toggleMenu} />);
                            case 'customerAdd':
                                return (<PanelCustomerAdd onToggle={toggleMenu} onClose={closeMenu} />);
                            case 'lead':
                                return (<PanelLeads onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
