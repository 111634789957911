import { useParams } from 'react-router-dom';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import LayoutCMS from '../layouts/LayoutCMS';
import LayoutCustomer from '../layouts/LayoutCustomer';
import LayoutOrder from '../layouts/LayoutOrder';
import LayoutStaff from '../layouts/LayoutStaff';
import LayoutSetting from '../layouts/LayoutSetting';

import LayoutProduct from '../layouts/LayoutProduct';

export default function PageHome() {

    const { page } = useParams();

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <NavigationBar />
            {(() => {
                switch (page) {
                    case 'overview':
                        return (<LayoutCMS />);
                    case 'customer':
                    case 'customerAdd':
                    case 'lead':
                        return (<LayoutCustomer />);
                    case 'product':
                    case 'productAdd':
                    case 'productEdit':
                    case 'category':
                    case 'media':
                        return (<LayoutProduct />);
                    case 'staff':
                    case 'staffAdd':
                        return (<LayoutStaff />);
                    case 'setting':
                        return (<LayoutSetting />);
                    case 'order':
                    case 'orderAdd':
                    case 'orderEdit':
                        return (<LayoutOrder />);
                    default:
                        return (
                            <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                Coming Soon
                            </div>
                        );
                }
            })()}
        </div>
    )
}
