import React from 'react';
import { useParams } from 'react-router-dom';

import PanelOrderList from '../panels/order/PanelOrderList';
import PanelOrderAdd from '../panels/order/PanelOrderAdd';
import PanelOrderEdit from '../panels/order/PanelOrderEdit';

import PanelDev from '../panels/dev/PanelDev';

export default function LayoutOrder() {

    const { page } = useParams();

    return (
        <div className='h-screen w-full'>
            {
                (() => {
                    switch (page) {
                        case 'order':
                            return (<PanelOrderList />);
                        case 'orderAdd':
                            return (<PanelOrderAdd />);
                        case 'orderEdit':
                            return (<PanelOrderEdit />);
                        default:
                            return (<PanelDev />);
                    }
                })()
            }
        </div>
    )
}
