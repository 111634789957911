import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import TopBar from '../../core/widgets/ui/TopBar';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import Scrollbars from 'react-custom-scrollbars';
import { motion } from "framer-motion";
import { Dialog, Menu, Transition } from '@headlessui/react';

import ServiceCategory from '../../services/serviceCategory';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';
import animPage from '../../assets/anim/anim-pageload.json';

export default function PanelCategory(props) {

    const [token] = useRecoilState(tokenAtom);

    const [category, setCategories] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);

    const categoryService = new ServiceCategory();

    const formVSchema = Yup.object().shape({
        title: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            title: '',
        },
        validationSchema: formVSchema,
        enableReinitialize: true,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "title": values.title,
            };
            categoryService.setCategory(body, token).then((res) => {
                setSubmit(false);
                if (res.status) {
                    resetForm();
                    setIsOpen(false);
                    loadData();
                    toast.success("New Cateogry Added", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                } else {
                    toast.error("Server error, please try again!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            });
        }
    });

    const getStatusClass = (status) => {
        switch (status) {
            case "active":
                return "bg-green-100 text-green-800 text-center py-1 font-medium rounded-full w-[80px]";
            case "archived":
                return "bg-red-100 text-red-800 text-center py-1 font-medium rounded-full w-[80px]";
            default:
                return "text-gray-900"
        }
    }

    const delCategory = (id) => {
        setLoading(true);
        let body = {
            'cid': id,
        };
        categoryService.delCategory(body, token).then((res) => {
            if (res.status) {
                toast.success("Category deleted", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                toast.error("Server error, please try again!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
            loadData();
        });
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {};
            var doc = await categoryService.getAllCategories(body, token);
            setCategories(doc.data);
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <Dialog open={isOpen} onClose={() => {
                setIsOpen(false);
                resetForm();
            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Add Collection &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpen(false);
                            resetForm();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Collection Name<sup className="text-red-600">*</sup></label>
                            <input type="text" id="title" value={values.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" onChange={handleChange} />
                            {(errors.title && touched.title) && <p className='text-xs text-red-400 mt-1'>{errors.title}</p>}
                        </div>
                        <div className='flex items-end justify-end'>
                            <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "ADD"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10">
                                <h2 class="text-2xl font-medium truncate ml-2">
                                    <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i>  Categories Master
                                </h2>
                                <div className='flex z-50'>
                                    <button className="py-1 px-3 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4" onClick={() => {
                                        setIsOpen(true);
                                    }}><i className="las la-plus mr-2"></i> Add Category</button>
                                    <TopBar />
                                </div>
                            </div>
                        </div>
                        <hr className='mt-4 col-span-12' />
                        {
                            category.length === 0 && <div className="intro-y mt-6 col-span-12 flex items-center justify-center">
                                <p>No Data Found.</p>
                            </div>
                        }
                        <div className='col-span-12 grid grid-cols-4 gap-4'>
                            {
                                category.length !== 0 && category.map((cat) => {
                                    return <div className='intro-y col-span-1 p-2 border rounded-md my-4 flex' key={cat._id}>
                                        <img src="https://via.placeholder.com/1024x576?text=No File" alt="item" className='w-1/2 shadow-inner' />
                                        <div className='px-4 flex flex-col justify-between w-full'>
                                            <div className='flex items-center justify-between'>
                                                <p className='text-lg font-medium'>{cat.title}</p>
                                                <Menu>
                                                    <Menu.Button>
                                                        <motion.i whileHover={{ scale: 1.1 }} className="las la-ellipsis-v text-lg"></motion.i>
                                                    </Menu.Button>
                                                    <Transition
                                                        enter="transition duration-100 ease-out"
                                                        enterFrom="transform scale-95 opacity-0"
                                                        enterTo="transform scale-100 opacity-100"
                                                        leave="transition duration-75 ease-out"
                                                        leaveFrom="transform scale-100 opacity-100"
                                                        leaveTo="transform scale-95 opacity-0">
                                                        <Menu.Items>
                                                            <div
                                                                className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                <div>
                                                                    <ul className='flex flex-col items-start p-2'>
                                                                        {
                                                                            cat.status === "active" && <Menu.Item>
                                                                                <li className="w-40 cursor-pointer hover:bg-red-100 p-2 rounded-md" onClick={() => {
                                                                                    delCategory(cat._id);
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <p className="text-sm font-serif ml-2 font-normal">Deleted</p>
                                                                                    </div>
                                                                                </li>
                                                                            </Menu.Item>
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                            <p className={`text-xs uppercase ${getStatusClass(cat.status)}`}>{cat.status}</p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </Scrollbars>
            }
        </div>
    )
}
