import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceOrder {
    lookupService = new APIServiceLookUp();

    async getAllOrder(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/getAllOrders`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateOrderById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/updateOrder`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteOrderById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/deleteOrderById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getOrderById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/getOrderById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async addOrder(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/addOrder`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}