import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { authAtom, pathAtom, userAtom } from '../../config/atoms';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import icLogo from '../../../assets/image/logo-icon.png';

export default function NavigationBar() {

    const { page } = useParams();

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [path, setPath] = useRecoilState(pathAtom);
    const [userData, setUserData] = useRecoilState(userAtom);

    const navigate = useNavigate();

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        navigate('/');
        setPath({ main: '', sub: '' });
    }

    const changeMainMenu = (link) => {
        navigate(`/home/${link}`);
    }

    const getStaff = () => {
        if (page === 'staff' || page === 'staffAdd') {
            return true;
        }
        return false;
    }

    const getCutomer = () => {
        if (page === 'customer' || page === 'customerAdd' || page === 'lead') {
            return true;
        }
        return false;
    }

    const getOrder = () => {
        if (page === 'order' || page === 'orderAdd' || page === 'orderEdit') {
            return true;
        }
        return false;
    }

    const getHome = () => {
        if (page === 'overview' || page === 'cms') {
            return true;
        }
        return false;
    }

    const getProduct = () => {
        if (page === 'product' || page === 'productAdd' || page === 'productEdit' || page === 'category' || page === 'media') {
            return true;
        }
        return false;
    }

    return (
        <>
            {authStatus === 'default' && <Navigate to="/" />}
            <div className="h-screen shadow-md bg-white hidden xs:block">
                <span className="cursor-pointer" onClick={() => { changeMainMenu('overview') }}>
                    <div className="w-16 h-16">
                        <img src={icLogo} className="w-12 m-auto py-2" />
                    </div>
                </span>
                <div className='w-3/5 h-[2px] bg-primeLight rounded-full mx-[20%] my-1'></div>
                <ul className="flex flex-col items-center">
                    <Tippy content="Overview Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl ${getHome() ? 'bg-prime hover:bg-primeLight text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('overview') }}>
                            <i className="las la-info-circle w-full text-2xl text-center" />
                        </li>
                    </Tippy>
                    <Tippy content={"Customer Section"} placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl ${getCutomer() ? 'bg-prime hover:bg-primeLight text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('customer') }}>
                            <i className="las la-user-alt w-full text-2xl text-center" />
                        </li>
                    </Tippy>
                    <Tippy content="Orders Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl ${getOrder() ? 'bg-prime hover:bg-primeLight text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('order') }}>
                            <i className="las la-paste w-full text-2xl text-center" />
                        </li>
                    </Tippy>
                    <Tippy content="Products Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl ${getProduct() ? 'bg-prime hover:bg-primeLight text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('product') }}>
                            <i className="las la-book w-full text-2xl text-center" />
                        </li>
                    </Tippy>
                    <Tippy content="Staff Section" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl ${getStaff() ? 'bg-prime hover:bg-primeLight text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('staff') }}>
                            <i className="las la-user-tie w-full text-2xl text-center" />
                        </li>
                    </Tippy>
                    <Tippy content="Settings" placement='right'>
                        <li className={`cursor-pointer w-12 h-12 my-1 flex items-center justify-center rounded-full hover:rounded-2xl ${page === 'setting' ? 'bg-prime hover:bg-primeLight text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('setting') }}>
                            <i className="las la-cog w-full text-2xl text-center" />
                        </li>
                    </Tippy>
                </ul>
                <div className='w-3/5 h-[2px] bg-primeLight rounded-full mx-[20%] my-1'></div>
                <div className='mb-4'></div>
                <div className="flex flex-col items-center">
                    <Tippy content="Logout" placement='right'>
                        <div className={`cursor-pointer w-12 h-12 my-1 mx-[12%] flex items-center justify-center rounded-full hover:rounded-2xl ${page === 'logout' ? 'bg-red-600 hover:bg-red-700 text-white' : 'hover:bg-red-200 text-gray-900'}`} onClick={() => { logout() }}>
                            <i className="las la-sign-out-alt w-full text-2xl text-center" />
                        </div>
                    </Tippy>
                </div>
            </div>
            <div className="fixed bottom-0 bg-white nShadow h-12 w-full rounded-t-md px-2 xs:hidden">
                <ul className="grid grid-cols-6 gap-2">
                    <Tippy content="Overview Section" placement='top'>
                        <li className={`cursor-pointer col-span-1 ${getHome() ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('overview') }}>
                            <i className="las la-info w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                    <Tippy content={"Customer Section"} placement='right'>
                        <li className={`cursor-pointer col-span-1 ${getCutomer() ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('customer') }}>
                            <i className="las la-user-alt w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                    <Tippy content="Orders Section" placement='top'>
                        <li className={`cursor-pointer col-span-1 ${getOrder() ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('order') }}>
                            <i className="las la-paste w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                    <Tippy content="Products Section" placement='top'>
                        <li className={`cursor-pointer col-span-1 ${getProduct() ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('product') }}>
                            <i className="las la-book w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                    <Tippy content="Staff Section" placement='top'>
                        <li className={`cursor-pointer col-span-1 ${getStaff() ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('staff') }}>
                            <i className="las la-user-tie w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                    <Tippy content="Settings" placement='top'>
                        <li className={`cursor-pointer col-span-1 ${page === 'setting' ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('setting') }}>
                            <i className="las la-cog w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                </ul>
            </div>
        </>
    )
}
