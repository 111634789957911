import React, { useState } from 'react';

import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';
import { toast } from 'react-toastify';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../core/config/atoms';

import ServiceOrder from '../services/serviceOrder';
import ServiceEmail from '../services/serviceEmail';

import animBtnLoading from '../assets/anim/anim-btnLoading.json';

export default function DigShipping({ ...props }) {

    const [token] = useRecoilState(tokenAtom);

    const [submit, setSubmit] = useState(false);

    const orderService = new ServiceOrder();
    const emailService = new ServiceEmail();

    const formVSchema = Yup.object().shape({
        vname: Yup.string().required('This information is required'),
        tnum: Yup.string().required('This information is required'),
        link: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            'vname': '',
            'tnum': '',
            'link': ''
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                uid: props.oid,
                data: {
                    vname: values.vname,
                    tnum: values.tnum,
                    link: values.link,
                    status: 'shipped'
                }
            };
            orderService.updateOrderById(body, token).then((res) => {
                let emailBody = {
                    name: res.data.customer.name,
                    email: res.data.customer.email,
                    oid: res.data.OID,
                    tnum: res.data.tnum,
                    vname: res.data.vname,
                    tLink: res.data.link
                };
                emailService.sendEmailB(emailBody, token).then((res) => {
                    setSubmit(false);
                    props.onClosed();
                });
            });
        }
    });
    return (
        <Dialog open={props.status} onClose={() => {
            props.onClosed();
        }} className='overflow-y-auto overflow-x-hidden z-[100] w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                <div className='flex items-center justify-between'>
                    <h3 className="text-xl font-semibold text-gray-900">
                        Add Shipping Details &nbsp;
                    </h3>
                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                        props.onClosed();
                        resetForm();
                    }}>
                        <i className="las la-times text-sm text-black"></i>
                    </span>
                </div>
                <hr className='mt-2' />
                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} autoComplete="off">
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Delivery Vender<sup className="text-red-600">*</sup></label>
                        <input type="text" value={values.vname} name="vname" id="vname" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. FedEx" onChange={handleChange} />
                        {(errors.vname && touched.vname) && <p className='text-xs text-red-400 mt-1'>{errors.vname}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Tracking Number<sup className="text-red-600">*</sup></label>
                        <input type="text" value={values.tnum} name="tnum" id="tnum" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. XXXXXXX" onChange={handleChange} />
                        {(errors.tnum && touched.tnum) && <p className='text-xs text-red-400 mt-1'>{errors.tnum}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Tracking Link<sup className="text-red-600">*</sup></label>
                        <input type="text" value={values.link} name="link" id="link" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. https://Fedex.com/tracking" onChange={handleChange} />
                        {(errors.link && touched.link) && <p className='text-xs text-red-400 mt-1'>{errors.link}</p>}
                    </div>
                    <div className='flex items-end'>
                        <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "Add"}
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    )
}
