import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceCategory {
    lookupService = new APIServiceLookUp();

    async getAllCategories(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}category/getAllCategories`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setCategory(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}category/setCategory`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delCategory(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}category/delCategory`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}