import React, { useState, useEffect } from 'react';

import { Scrollbars } from 'react-custom-scrollbars';
import Lottie from 'lottie-react';

import TopBar from '../core/widgets/ui/TopBar';

import ComponentSalesCountCard from '../panels/home/components/card/ComponentSalesCountCard';
import ComponentOrderCountCard from '../panels/home/components/card/ComponentOrderCountCard';
import ComponentOrderPCountCard from '../panels/home/components/card/ComponentOrderPCountCard';
import ComponentOrderDCountCard from '../panels/home/components/card/ComponentOrderDCountCard';
import ComponentCustomerCountCard from '../panels/home/components/card/ComponentCustomerCountCard';

import ComponentNewOrderList from '../panels/home/components/list/ComponentNewOrderList';
import ComponentExpiryOrderList from '../panels/home/components/list/ComponentExpiryOrderList';

import animPage from '../assets/anim/anim-pageload.json';

export default function LayoutCMS() {

    const [loading, setLoading] = useState(true);

    const loadData = () => {
        (async function () {
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className='h-screen w-full'>
            <div className="w-full h-full py-3 px-3">
                {
                    loading && <div className='flex items-center justify-center w-full h-full'>
                        <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                    </div>
                }
                <div className="intro-y flex items-center justify-between h-10">
                    <h2 className="text-2xl font-medium truncate ml-2">
                        Overview
                    </h2>
                    <div className='z-50'>
                        <TopBar />
                    </div>
                </div>
                <hr className='mt-2' />
                {
                    !loading && <Scrollbars>
                        <div className="grid grid-cols-4 lg:grid-cols-10 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4">
                            <ComponentSalesCountCard />
                            <ComponentOrderCountCard />
                            <ComponentOrderPCountCard />
                            <ComponentOrderDCountCard />
                            <ComponentCustomerCountCard />
                        </div>
                        <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4'>
                            <ComponentNewOrderList />
                        </div>
                        <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2 px-4'>
                            <ComponentExpiryOrderList />
                        </div>
                        <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'></div>
                    </Scrollbars>
                }
            </div>
        </div>
    );
}
