import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceMedia {
    lookupService = new APIServiceLookUp();

    async getAllMedia(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}media/getAllMedia`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setMedia(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}media/setMedia`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateMedia(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}media/updateMedia`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delMedia(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}media/delMedia`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}