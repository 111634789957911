import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import PanelProduct from '../panels/product/PanelProduct';
import PanelAddProduct from '../panels/product/PanelAddProduct';
import PanelEditProduct from '../panels/product/PanelEditProduct';
import PanelCategory from '../panels/product/PanelCategory';
import PanelMedia from '../panels/product/PanelMedia';

import PanelDev from '../panels/dev/PanelDev';

export default function LayoutProduct() {

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-xl lg:text-2xl font-medium pt-4 ml-6">Product Menu</motion.h1>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start mt-2 lg:mt-4">
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'product' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('product') }}>Product</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'category' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('category') }}>Category</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${page === 'media' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeNav('media') }}>Media Center</li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'category':
                                return (<PanelCategory onToggle={toggleMenu} />);
                            case 'media':
                                return (<PanelMedia onToggle={toggleMenu} />);
                            case 'product':
                                return (<PanelProduct onToggle={toggleMenu} />);
                            case 'productAdd':
                                return (<PanelAddProduct onToggle={toggleMenu} onClose={closeMenu} />);
                            case 'productEdit':
                                return (<PanelEditProduct onToggle={toggleMenu} onClose={closeMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    );
}
