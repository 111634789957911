import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceCustomer {
    lookupService = new APIServiceLookUp();

    async getAllCustomer(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/getAllCustomer`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setCustomer(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/setCustomer`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateCustomerById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/updateCustomer`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteCustomerById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}customer/deleteCustomerById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}