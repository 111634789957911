import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceOverview {
    lookupService = new APIServiceLookUp();

    async getCustomerCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getCustomerCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getOrderCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getOrderCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getOrderDCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getOrderDCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getOrderPCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getOrderPCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getSalesCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getSalesCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getNewOrders(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getNewOrders`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getUpcomingOrders(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}agg/getUpcomingOrders`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}